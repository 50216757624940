@use '@angular/material' as mat;

@include mat.core();

$primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $primary,
   accent: $my-accent,
 ),
 density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme);

// Include the theme mixins for other components you use here.

@font-face {
  font-family: "Roblox";
  src: local("Roblox"), url(./assets/fonts/roblox/RobloxFontRegular.ttf) format("truetype");
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(228, 225, 225);
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 3px;
}


html,
body {
  height: 100%;
}

a:link
{
text-decoration:none;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

img {
  max-width: 100%;
}

.webTitle {
  font-family: 'Roblox', cursive;;
  font-size: 5vh;
  margin-right: 15px;
}

.mainTitle {
  left: 25%;
  margin-top: -30%;
  font-family: 'Roblox', cursive;
  font-size: 5vw;
  color: white;
  position: absolute;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.coverImage {
  max-width: 100%;
}

.mat-toolbar {
  box-shadow: 0 4px 2px -2px black;
  background-color: black !important;
}

.socialIcon {
  margin-top: 5px;
  height: 36px;
  margin-right: 5px;
}

.socialIcon:hover {
  transform: scale(1.04);
}

.mat-mdc-menu-content  {
  background-color: #171C33;
  color: white;
}

.mat-mdc-menu-panel {
  background-color: #171C33 !important;
}

.mat-mdc-menu-item {
  color: white !important;

  > .mat-icon {
    color: white !important;
  }
}

.mat-mdc-menu-item:hover {
  background-color: #222b52 !important;
}

.mat-mdc-dialog-surface {
  background-color: #171C33 !important;
  color: white !important;
  padding: 1%;
}

.mat-menu-panel {
  background: transparent !important;
}

.mat-menu-item > .mat-icon {
  color: white;
}

.speed-dial-container {
  position: absolute;
  right: 15px;
}

.speed-dial-container.top {
  top: 15px;
}

.speed-dial-container.bottom {
  bottom: 15px;
}

.mat-fab.mat-accent {
  background-color: #01579b;
}

.example-card {
  max-width: 400px;
}

.homeSection {
  margin-top: 45%;
  margin-bottom: 10%;
  font-family: 'Roboto', sans-serif;
}

.homeSectionTitle {
  font-family: 'Roblox', cursive;
  font-size: 5vw;
  color: white;
  text-align: center;
}

.cardheader > .mat-card-header-text {
  margin: 0 !important;
}

.homeSection > p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1vw;
  padding-left: 25%;
  padding-right: 25%;
  text-align: justify;
  line-height: 1.2vw;
}

.articleNotfound {
  margin-top: 15%;
}

app-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

app-footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

// change underline color
.mat-form-field-ripple {
  background-color: white !important;
}

// change label color
.mat-focused .mat-form-field-label {
  color: white !important;
}

// change label color
.mat-form-field-label {
  color: white !important;
}

//change input color
.mat-form-field .mat-form-field-wrapper .mat-form-field-infix{
  color: white !important;
}


.mat-form-field-empty.mat-form-field-label {
  color: white;
}

.mat-form-field-underline {
  background-color: white;
}

.sendButton {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.mat-raised-button[disabled][disabled] {
  background: rgba(158, 158, 158, 0.294) !important;
  color: white;
}

.mat-form-field-infix {
  color: white;
}

.mat-raised-button {
  background-color: #01579b;
  color: white;
}

.mat-card-image {
  max-width: none;
}

.mat-card {
  height: 100%;
}

.mat-card:hover {
  box-shadow: 0 0 10px #171C33;
}

.mat-card-content > p {
  font-size: 0.8vw;
}

@media (min-width:601px) and (max-width: 1400px) {
  .mat-card-content > p {
    font-size: 1.5vw;
  }
}

@media (max-width: 600px) {
  .homeSection {
    margin-top: 30%;
  }

  .homeSection > p {
    font-size: 3.5vw;
    line-height: 4vw;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-card {
    height: 25vw !important;
    max-height: 400px;
  }

  .mat-card-content > p {
    font-size: 3vw;
  }
}
